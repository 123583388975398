import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="max-w-5xl mx-auto mb-10 text-center border-t border-gray-200 px-4 sm:px-6">
        <h6 className="text-white text-3xl font-semibold mb-2 mt-8"><b>Disclaimer</b></h6>
        <p className="text-justify text-lg text-white font-normal">The information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. ShibaSUI AI team accepts no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published. DogeSlurp is not a security as per US financial regulation, Europe, UK, Canada, or any other regulation. ShibaSUI AI's team, developers, and other stakeholders created this as a fun community token only. The ownership of <b>$SSAI</b> coin does not give any right of return, vote, management, or any other right.</p>

      </div>
    </footer>
  );
}

export default Footer;
