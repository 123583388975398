import React, { useRef } from "react";

import Header from "../partials/Header";
import HeroHome from "../partials/HeroHome";
import Chart from "../partials/Chart";
import FeaturesBlocks from "../partials/FeaturesBlocks";
import Testimonials from "../partials/Testimonials";
import Newsletter from "../partials/Newsletter";
import Footer from "../partials/Footer";
import MintNFT from "../partials/MintNFT";

function Home() {
  const scrollRef = useRef(null);

  const handleClick = () => {
    window.scrollTo(0, scrollRef.current.offsetTop - 150);
  };

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header scrollClick={handleClick}/>

      {/*  Page content */}
      <main className="flex-grow bg-gray-900">
        <div style={{ 
          backgroundImage: `url("/background.jpg")`,
          height: '100%',
          backgroundRepeat: 'no-repeat',
          backgroundSize:'100vw',
        }}>

          {/*  Page sections */}
          <HeroHome scrollClick={handleClick} />
          <MintNFT scrollRef={scrollRef} />
          <FeaturesBlocks />
          {/* <FeaturesHome /> */}
          <Testimonials />
          {/* <Newsletter /> */}
          <Footer />
        </div>
      </main>

      {/*  Site footer */}
      {/* <Footer /> */}
    </div>
  );
}

export default Home;
