import React from 'react';
import { Carousel } from 'react-carousel-minimal';

function HeroHome(props) {
  const {scrollClick} = props;
  const data = []
  for (let i = 0; i < 12; i++) {
    data.push({
      image: `/demo/${i+1}.jpg`,
      caption: ""
    })
  }
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        {/* Hero content */}
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">

          {/* Section header */}
          <div className="text-center pb-12 md:pb-16">
            <h1 className="text-5xl md:text-6xl text-yellow-100 font-extrabold leading-tighter tracking-tighter text-white mb-4" data-aos="zoom-y-out" style={{textShadow
            : "0px 0px 5px black"}}>Shiba<span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-blue-300" style={{textShadow
              : "none"}}>SUI</span> AI</h1>
            <h2 className="text-3xl md:text-3xl font-extrabold leading-tighter tracking-tighter text-white drop-shadow-lg shadow-black border-black mb-4 "><span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-300 to-blue-200">The first Shiba Inu meme NFTs and Game-Fi on SUI. #BuildOnSui</span></h2>
            <div className="max-w-3xl mx-auto">
              
              <div className="max-w-xs mx-auto sm:max-w-none sm:flex sm:justify-center" data-aos="zoom-y-out" data-aos-delay="300">
                <div>
                  <a className="btn text-white bg-indigo-500 hover:bg-indigo-700 w-full mb-4 sm:w-auto sm:mb-0" href="https://dex.bluemove.net/swap/0x2::sui::SUI/0xb82df8ce0c1bb6de1b16a29744397713886bdadfa2633bddeac346dacfef854e::ssai::SSAI">Buy-the-dip Now</a>
                </div>
                <div>
                  <a className="btn text-white bg-gray-500 hover:bg-gray-700 w-full sm:w-auto sm:ml-4" href="#chart" onClick={scrollClick}>Mint NFTs</a>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div className='mt-30' style={{
            padding: "0 20px"
          }}>
            <Carousel
              data={data}
              time={3000}
              width="850px"
              height="500px"
              captionStyle={captionStyle}
              radius="10px"
              slideNumber={false}
              slideNumberStyle={slideNumberStyle}
              captionPosition="bottom"
              automatic={true}
              dots={false}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={false}
              thumbnailWidth="100px"
              style={{
                textAlign: "center",
                maxWidth: "850px",
                maxHeight: "500px",
                margin: "40px auto",
              }}
            />
            <p className='text-3xl text-center md:text-3xl text-yellow-100 font-bold'>Shiba Inu GAME-Fi concept arts</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;