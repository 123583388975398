import { useEffect, useState } from 'react'


function MintNFT(props) {
    const { scrollRef } = props;

  return (
    <>
        <div className='mx-10 flex justify-center items-center'ref={scrollRef}>
          <div className="bg-white rounded-lg p-4">
            <div className="flex justify-between items-center">
              <span className='heading font-bold text-xl from-blue-500 to-blue-300'>ShibaSUI AI NFT 🚀</span>
              <span className='font-semibold'>0/1000</span>
            </div>
            <div className="mt-3 border-gray-600 border-2 rounded-lg relative">
                <img src="/nft.png"  className='block filter blur' layout='responsive' width='100%' height='100%' priority={true} placeholder="blur"/>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#FFFFFF" className="w-16 h-16 absolute bottom-20 left-1/2 -translate-x-1/2 transform">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <span className='font-medium text-xl text-white absolute bottom-10 left-1/2 -translate-x-1/2 transform'>Coming Soon</span>
            </div>

            <div className="mt-5 space-x-10 flex justify-center">
                <button class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Mint NFT 
                </button>
                <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Connect Wallet 
                </button>
            </div>
          </div>
  
        </div>
        {/* {succes? (
          <div className="p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg fixed bottom-0 right-4" role="alert">
            <span className="font-medium">{succes}</span>
          </div>
        ) 
        : 
        errormsg? (
          <div className="p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg fixed bottom-0 right-4" role="alert">
            <span className="font-medium"> {errormsg} </span>
          </div>
        ) : <></>} */}
    </>
  )
}

export default MintNFT