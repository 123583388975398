import React from 'react';
import { Link } from "react-router-dom";

function FeaturesBlocks() {
  return (
    <section className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>
      <div className="absolute left-0 right-0 bottom-0 m-auto w-px p-px h-20 bg-gray-200 transform translate-y-1/2"></div> */}

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 mb-4 text-white">Tokenomics</h2>
            {/* <p className="text-lg font-medium text-white">We tried our best to protect HOLDERS from rugpull. Holders get more $SSAI by reflection from tax fee. If you sell one token, you are not HOLDER anymore!</p> */}
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="relative min-h-full flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <img className="w-16 h-16 md:min-h-full p-1 -mt-1 mb-2" src={require('../images/defi-icons/smart-contracts.png').default} width="64" height="64"/>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">CONTRACT</h4>
              <a href="https://explorer.sui.io/object/0xb82df8ce0c1bb6de1b16a29744397713886bdadfa2633bddeac346dacfef854e?network=mainnet" className="text-blue-700 font-semibold text-center">$SSAI </a>
              <p className="text-gray-700 text-center break-words">Holding tokens gives you a chance to mint NFTs earlier</p>
            </div>

            {/* 2nd item */}
            <div className="relative min-h-full flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <img className="w-16 h-16 md:min-h-full p-1 -mt-1 mb-2" src={require('../images/defi-icons/coins.png').default} width="64" height="64"/>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">SUPPLY</h4>
              <div>
                <p className="text-gray-700 text-center">314,000,000,000 tokens launched</p>
                <li className="text-gray-700 list-disc">90% - 281,030,000,000 tokens burned</li>
                <li className="text-gray-700 list-disc">08% - 25,120,000,000 tokens circulating </li>
                <li className="text-gray-700 list-disc">02% - 6,280,000,000 tokens for project</li>
              </div>
            </div>

            {/* 3rd item */}
            {/* <div className="relative min-h-full flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <img className="w-16 h-16 md:min-h-full p-1 -mt-1 mb-2" src={require('../images/defi-icons/cryptocurrency.png').default} width="64" height="64"/>
              <h4 className="text-xl min-h-full font-bold leading-snug tracking-tight mb-1">Pro-Fellowship</h4>
              <p className="text-gray-700 text-center">No wallet can buy &gt; 0.5% of supply.</p>
              <p className="text-gray-700 text-center">No tx can send &gt; 0.1% of supply.</p>
            </div> */}

            {/* 4th item */}
            {/* <div className="relative min-h-full flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <img className="w-16 h-16 md:min-h-full p-1 -mt-1 mb-2" src={require('../images/defi-icons/safe-box.png').default} width="64" height="64"/>         
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">LP locked forever</h4>
              <p className="text-gray-700 text-center">All LP tokens were sent to the zero & burn address</p>
            </div> */}

            {/* 5th item */}
            <div className="relative min-h-full flex flex-col items-center p-6 bg-white rounded shadow-xl">
            <img className="w-16 h-16 md:min-h-full p-1 -mt-1 mb-2" src={require('../images/defi-icons/lending.png').default} width="64" height="64"/>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Ownership Renounced</h4>
              <p className="text-gray-700 text-center">Ownership of the contract was renounced.</p>
            </div>

            {/* 6th item */}
            {/* <div className="relative min-h-full flex flex-col items-center p-6 bg-white rounded shadow-xl">
              <img className="w-16 h-16 md:min-h-full p-1 -mt-1 mb-2" src={require('../images/defi-icons/fee.png').default} width="64" height="64"/>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Transaction Fees</h4>
              <p className="text-gray-700 text-center">5% to HOLDERS</p>
              <p className="text-gray-700 text-center">5% to Liquidity</p>
            </div> */}

          </div>

        </div>
      </div>
    </section>
  );
}

export default FeaturesBlocks;
