import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

function Header(props) {
  const {scrollClick} = props;
  const [top, setTop] = useState(false);
  const [price, setPrice] = useState(0);
  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset >= 0 ? setTop(false) : setTop(true);
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top]);

  // useEffect(() => {
  //   fetch("https://api.pancakeswap.info/api/v2/tokens/0xae2df9f730c54400934c06a17462c41c08a06ed8")
  //     .then((response) => response.json())
  //     .then((object) => setPrice(object.data.price * (100000000000-40404404404)));

  // }, []);
  
  return (
    <header
      className={`fixed w-full z-20 md:bg-opacity-60 transition duration-300 ease-in-out ${
        !top && "bg-white blur shadow-lg"
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-16">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            <img
              className="relative rounded-full"
              src="/logo-128.png"
              width="48"
              height="48"
              alt="Logo"
            />
            <Link to="/" className="block" aria-label="Cruip" />
          </div>
          {/* <p className="font-medium">Market Cap: ${price.toLocaleString('en', {maximumFractionDigits:2})}</p> */}
          <p className="font-medium">Market Cap: Coming Soon</p>
          {/* Site navigation */}
          <nav className="flex flex-grow">
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link
                  to="/#"
                  onClick={scrollClick}
                  className="font-semibold text-gray-600 hover:text-gray-900 px-2 md:px-5 flex items-center transition duration-150 ease-in-out"
                >
                  NFT Mint
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: `https://twitter.com/ShibaSuiAI` }}
                  target="_blank"
                  className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-600 md:ml-3 md:flex ml-2 hidden"
                >
                  <img src={require("../images/socials/twitter.png").default} width="16" height="16" alt="twt" />
                </Link>
              </li>
              <li>
                <Link
                  to={{ pathname: `https://nft-ai.ShibaSUI.art` }}
                  target="_blank"
                  className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-600 md:ml-3 md:flex ml-2 hidden"
                >
                  <img src={require("../images/socials/telegram.png").default} width="16" height="16" alt="tele" />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
